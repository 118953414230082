// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-pnp-js": () => import("./../../../src/pages/pnp.js" /* webpackChunkName: "component---src-pages-pnp-js" */),
  "component---src-pages-tnc-js": () => import("./../../../src/pages/tnc.js" /* webpackChunkName: "component---src-pages-tnc-js" */),
  "component---src-templates-about-index-js": () => import("./../../../src/templates/About/index.js" /* webpackChunkName: "component---src-templates-about-index-js" */),
  "component---src-templates-agencies-index-js": () => import("./../../../src/templates/Agencies/index.js" /* webpackChunkName: "component---src-templates-agencies-index-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/Blog/index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-blog-post-index-js": () => import("./../../../src/templates/BlogPost/index.js" /* webpackChunkName: "component---src-templates-blog-post-index-js" */),
  "component---src-templates-contact-index-js": () => import("./../../../src/templates/Contact/index.js" /* webpackChunkName: "component---src-templates-contact-index-js" */),
  "component---src-templates-home-index-js": () => import("./../../../src/templates/Home/index.js" /* webpackChunkName: "component---src-templates-home-index-js" */),
  "component---src-templates-how-index-js": () => import("./../../../src/templates/How/index.js" /* webpackChunkName: "component---src-templates-how-index-js" */),
  "component---src-templates-landing-index-js": () => import("./../../../src/templates/Landing/index.js" /* webpackChunkName: "component---src-templates-landing-index-js" */),
  "component---src-templates-offering-index-js": () => import("./../../../src/templates/Offering/index.js" /* webpackChunkName: "component---src-templates-offering-index-js" */),
  "component---src-templates-pricing-index-js": () => import("./../../../src/templates/Pricing/index.js" /* webpackChunkName: "component---src-templates-pricing-index-js" */),
  "component---src-templates-saas-index-js": () => import("./../../../src/templates/Saas/index.js" /* webpackChunkName: "component---src-templates-saas-index-js" */),
  "component---src-templates-services-index-js": () => import("./../../../src/templates/Services/index.js" /* webpackChunkName: "component---src-templates-services-index-js" */),
  "component---src-templates-startup-index-js": () => import("./../../../src/templates/Startup/index.js" /* webpackChunkName: "component---src-templates-startup-index-js" */),
  "component---src-templates-testimonials-index-js": () => import("./../../../src/templates/Testimonials/index.js" /* webpackChunkName: "component---src-templates-testimonials-index-js" */),
  "component---src-templates-why-index-js": () => import("./../../../src/templates/Why/index.js" /* webpackChunkName: "component---src-templates-why-index-js" */)
}

